<template>
  <div v-if="page_loading">
    <loading :page_loading="page_loading" />
  </div>
  <div v-else>
    <loading :loading="loading" />

    <!-- นำทาง -->
    <v-card elevation="0" class="v-card-bottom-30">
      <v-card-title class="body-1">
        ตั้งค่า
        <v-icon>mdi-chevron-right</v-icon>
        ตั้งค่าทั่วไป
      </v-card-title>
    </v-card>

    <!-- แสดงภาพ -->
    <v-dialog v-model="ShowPhoto" max-width="500">
      <v-card class="ma-0">
        <v-card-text>
          <v-row align="center">
            <v-col cols="12" class="text-center">
              <img class="hidden-md-and-up" :src="ShowPhotoLink" width="300" />
              <img class="hidden-sm-and-down" :src="ShowPhotoLink" width="450" />
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-btn text color="#1976d2" @click="Opentap(ShowPhotoLink)">เปิดแถบใหม่</v-btn>
          <v-spacer></v-spacer>
          <v-btn text color="#1976d2" @click="ShowPhoto = false">ปิด</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- ข้อมูลสาขา -->
    <v-card elevation="1" class="v-card-bottom-30">
      <v-card-title class="height-57">
        <v-icon size="22" color="teal darken-3">fa-address-card</v-icon
        ><span class="ml-3">ข้อมูลสาขา</span>
        <v-spacer />
        <v-btn
          :disabled="branch.isUpdate"
          :elevation="1"
          color="primary"
          class="pa-2"
          @click="branch.isUpdate = true"
        >
          <v-icon size="16" class="pr-1">fa-edit</v-icon>
          แก้ไข
        </v-btn>
      </v-card-title>
      <v-divider />
      <v-card-text>
        <v-form ref="formBranch" lazy-validation>
          <v-row>
            <v-col cols="12" md="6">
              <v-row>
                <v-col cols="12" md="4" class="pt-1 pb-2">
                  <h3 class="fn-14">ชื่อสาขา :</h3>
                </v-col>
                <v-col cols="12" md="8" class="pt-1 pb-2">
                  <span v-if="!branch.isUpdate">{{ branch.name }}</span>
                  <v-text-field
                    v-else
                    :value="branch.name"
                    @change="(value) => (branch.name = Trim_value(value))"
                    ref="branchname"
                    @keyup.enter="$refs.branchsenderName.focus()"
                    :rules="[(v) => Trim_rules(v) || 'กรุณาระบุ']"
                    autocomplete="off"
                    outlined
                    dense
                    hide-details
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" md="6" />
          </v-row>

          <v-row>
            <v-col cols="12" md="6">
              <v-row>
                <v-col cols="12" md="4" class="pt-1 pb-2">
                  <h3 class="fn-14">ชื่อที่ใช้ส่งสินค้า :</h3>
                </v-col>
                <v-col cols="12" md="8" class="pt-1 pb-2">
                  <span v-if="!branch.isUpdate">{{ branch.senderName }}</span>
                  <v-text-field
                    v-else
                    :value="branch.senderName"
                    @change="(value) => (branch.senderName = Trim_value(value))"
                    ref="branchsenderName"
                    @keyup.enter="$refs.branchphoneNumber.focus()"
                    :rules="[(v) => Trim_rules(v) || 'กรุณาระบุ']"
                    autocomplete="off"
                    outlined
                    dense
                    hide-details
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" md="6" />
          </v-row>

          <v-row>
            <v-col cols="12" md="6">
              <v-row>
                <v-col cols="12" md="4" class="pt-1 pb-2">
                  <h3 class="fn-14">เบอร์โทร :</h3>
                </v-col>
                <v-col cols="12" md="8" class="pt-1 pb-2">
                  <span v-if="!branch.isUpdate">{{ branch.phoneNumber }}</span>
                  <v-text-field
                    v-else
                    :value="branch.phoneNumber"
                    @change="(value) => (branch.phoneNumber = Trim_value(value))"
                    ref="branchphoneNumber"
                    @keyup.enter="$refs.branchemail.focus()"
                    :rules="[(v) => Trim_rules(v) || 'กรุณาระบุ']"
                    autocomplete="off"
                    outlined
                    dense
                    hide-details
                    maxlength="10"
                    @keypress="isNumberWNoDot($event)"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" md="6" />
          </v-row>

          <v-row>
            <v-col cols="12" md="6">
              <v-row>
                <v-col cols="12" md="4" class="pt-1 pb-2">
                  <h3 class="fn-14">อีเมล :</h3>
                </v-col>
                <v-col cols="12" md="8" class="pt-1 pb-2">
                  <span v-if="!branch.isUpdate">{{ branch.email }}</span>
                  <v-text-field
                    v-else
                    :value="branch.email"
                    @change="(value) => (branch.email = Trim_value(value))"
                    ref="branchemail"
                    @keyup.enter="$refs.branchaddress.focus()"
                    :rules="[(v) => Trim_rules(v) || 'กรุณาระบุ']"
                    autocomplete="off"
                    outlined
                    dense
                    hide-details
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" md="6" />
          </v-row>

          <v-row>
            <v-col cols="12" md="6">
              <v-row>
                <v-col cols="12" md="4" class="pt-1 pb-2">
                  <h3 class="fn-14">ที่อยู่ :</h3>
                </v-col>
                <v-col cols="12" md="8" class="pt-1 pb-2">
                  <span v-if="!branch.isUpdate">{{ branch.fullAddress }}</span>
                  <v-text-field
                    v-else
                    :value="branch.address"
                    @change="(value) => (branch.address = Trim_value(value))"
                    ref="branchaddress"
                    @keyup.enter="$refs.branchpostcodeMapping.focus()"
                    :rules="[(v) => Trim_rules(v) || 'กรุณาระบุ']"
                    autocomplete="off"
                    outlined
                    dense
                    hide-details
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" md="6" />
          </v-row>

          <v-row v-if="branch.isUpdate">
            <v-col cols="12" md="6">
              <v-row>
                <v-col cols="12" md="4" class="pt-1 pb-2">
                  <h3 class="fn-14">รหัสไปรษณีย์ :</h3>
                </v-col>
                <v-col cols="12" md="8" class="pt-1 pb-2">
                  <v-autocomplete
                    v-model="branch.postcodeMapping"
                    label="รหัสไปรษณีย์ / จังหวัด / (เขต/อำเภอ) / (แขวง/ตำบล)"
                    :items="postcode_mapping"
                    :item-text="
                      (item) =>
                        item.Postcode +
                        ' ' +
                        item.Province +
                        ' ' +
                        item.District +
                        ' ' +
                        item.SubDistrict
                    "
                    outlined
                    ref="branchpostcodeMapping"
                    :rules="[(v) => !!v || 'กรุณาระบุ']"
                    autocomplete="off"
                    required
                    return-object
                    dense
                    hide-details
                  ></v-autocomplete>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" md="6" />
          </v-row>
        </v-form>

        <v-row v-if="branch.isUpdate">
          <v-col cols="12" class="pt-1 pb-2">
            <v-btn :elevation="1" color="primary" class="px-2" @click="UpdateBranch()">
              <v-icon size="20" class="mr-1">mdi-content-save</v-icon>
              <span class="fn-14"><b> บันทึก </b></span>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <!-- ที่อยู่ใบกำกับภาษี -->
    <v-dialog
      v-model="invoice.dialog"
      scrollable
      max-width="50%"
      persistent
      :fullscreen="$vuetify.breakpoint.xsOnly"
    >
      <v-card elevation="1" class="ma-0">
        <v-card-title>
          <v-icon size="22" color="red darken-3">fa-map-marked-alt</v-icon>
          <span v-if="!invoice.isUpdate" class="ml-3">เพิ่มที่อยู่ใบกำกับภาษี</span>
          <span v-else class="ml-3">แก้ไขที่อยู่ใบกำกับภาษี</span>
          <v-spacer />
          <v-btn icon @click="InvoiceCloseDialog()"
            ><v-icon color="danger">mdi-close</v-icon></v-btn
          >
        </v-card-title>
        <v-divider />
        <v-card-text>
          <v-form ref="formInvoice" lazy-validation>
            <v-row>
              <v-col cols="12" md="3" class="pt-1 pb-2">
                <span class="fn-14"> โลโก้ : </span>
              </v-col>
              <v-col cols="12" md="9" class="pt-1 pb-2">
                <span>
                  <v-btn
                    :elevation="0"
                    class="btn-inputfile ma-0"
                    color="orange darken-2"
                    @click="$refs.inputfile.click()"
                    ><span>อัพโหลดรูปภาพ</span></v-btn
                  >
                  {{ files.filename }}
                </span>
                <input
                  class="display-none d-none"
                  ref="inputfile"
                  accept="image/*"
                  type="file"
                  @change="previewpicture($event)"
                />
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" md="3" class="pt-1 pb-2">
                <span class="fn-14"> ชื่อบุคคล/นิติบุคคล : </span>
              </v-col>
              <v-col cols="12" md="9" class="pt-1 pb-2">
                <v-text-field
                  :value="invoice.name"
                  @change="(value) => (invoice.name = Trim_value(value))"
                  ref="invoicename"
                  @keyup.enter="$refs.invoicephone.focus()"
                  :rules="invoice.rulesName"
                  autocomplete="off"
                  outlined
                  dense
                  hide-details
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" md="3" class="pt-1 pb-2">
                <span class="fn-14"> เบอร์โทร : </span>
              </v-col>
              <v-col cols="12" md="9" class="pt-1 pb-2">
                <v-text-field
                  :value="invoice.phone"
                  @change="(value) => (invoice.phone = Trim_value(value))"
                  ref="invoicephone"
                  @keyup.enter="$refs.invoiceidcardNumber.focus()"
                  :rules="invoice.rulesPhone"
                  autocomplete="off"
                  outlined
                  dense
                  hide-details
                  maxlength="10"
                  @keypress="isNumberWNoDot($event)"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" md="3" class="pt-1 pb-2">
                <span class="fn-14"> เลขผู้เสียภาษี : </span>
              </v-col>
              <v-col cols="12" md="9" class="pt-1 pb-2">
                <v-text-field
                  :value="invoice.idcardNumber"
                  @change="(value) => (invoice.idcardNumber = Trim_value(value))"
                  ref="invoiceidcardNumber"
                  @keyup.enter="$refs.invoiceaddress.focus()"
                  :rules="invoice.rulesIdCardNumber"
                  autocomplete="off"
                  outlined
                  dense
                  hide-details
                  maxlength="13"
                  @keypress="isNumberWNoDot($event)"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" md="3" class="pt-1 pb-2">
                <span class="fn-14"> ที่อยู่ : </span>
              </v-col>
              <v-col cols="12" md="9" class="pt-1 pb-2">
                <v-text-field
                  :value="invoice.address"
                  @change="(value) => (invoice.address = Trim_value(value))"
                  ref="invoiceaddress"
                  @keyup.enter="$refs.invoivepostcodeMapping.focus()"
                  :rules="invoice.rulesAddress"
                  autocomplete="off"
                  outlined
                  dense
                  hide-details
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" md="3" class="pt-1 pb-2">
                <span class="fn-14"> รหัสไปรษณีย์ : </span>
              </v-col>
              <v-col cols="12" md="9" class="pt-1 pb-2">
                <v-autocomplete
                  v-model="invoice.postcodeMapping"
                  label="รหัสไปรษณีย์ / จังหวัด / (เขต/อำเภอ) / (แขวง/ตำบล)"
                  :items="postcode_mapping"
                  :item-text="
                    (item) =>
                      item.Postcode +
                      ' ' +
                      item.Province +
                      ' ' +
                      item.District +
                      ' ' +
                      item.SubDistrict
                  "
                  outlined
                  ref="invoivepostcodeMapping"
                  :rules="invoice.rulesPostcodeMapping"
                  autocomplete="off"
                  required
                  return-object
                  dense
                  hide-details
                ></v-autocomplete>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" md="3" class="pt-1 pb-2">
                <span class="fn-14"> ตั้งเป็นค่าเริ่มต้น : </span>
              </v-col>
              <v-col cols="12" md="1" class="pt-1 pb-2">
                <v-checkbox
                  v-model="invoice.isDefault"
                  :readonly="invoice.isReadonly"
                  color="info"
                ></v-checkbox>
              </v-col>
              <v-col cols="12" md="8" class="pt-1 pb-2">
                <span v-if="invoice.isReadonly && invoice.isUpdate" class="text-danger"
                  >ไม่สามารถแก้ไขได้ !!</span
                >
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" class="pt-1 pb-2">
                <v-btn
                  :elevation="1"
                  color="primary"
                  class="px-2"
                  @click="UpdateInvoice()"
                >
                  <v-icon size="20" class="mr-1">mdi-content-save</v-icon>
                  <span class="fn-14"><b> บันทึก </b></span>
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-card elevation="1" class="v-card-bottom-30">
      <v-card-title class="height-57">
        <v-icon size="22" color="red darken-3">fa-map-marked-alt</v-icon
        ><span class="ml-3">ที่อยู่ใบกำกับภาษี</span>
        <v-spacer />
        <v-btn
          :elevation="1"
          color="primary"
          class="px-2"
          @click="OPPInvoice(false, null)"
        >
          <v-icon size="22" class="mr-1">mdi-plus</v-icon>
          เพิ่มที่อยู่
        </v-btn>
      </v-card-title>
      <v-divider />
      <v-card-text class="overflow-y-auto packhai-max500-min100 pa-0">
        <!-- PC hidden-xs-only -->
        <v-data-table
          :headers="headerInvoice"
          :items="dataInvoice"
          :items-per-page="9999999999"
          hide-default-footer
          class="packhai-border-table"
        >
          <template v-slot:item.delete="{ item }">
            <a v-if="!item.isDefault" @click="DeleteInvoice(item)"
              ><v-icon size="16" color="red darken-3">fa-trash-alt</v-icon></a
            >
            <v-icon v-else size="16">fa-trash-alt</v-icon>
          </template>
          <template v-slot:item.edit="{ item }">
            <a @click="OPPInvoice(true, item)"
              ><v-icon size="16" color="orange darken-3">fa-edit</v-icon></a
            >
          </template>
          <template v-slot:item.address="{ item }">
            <a v-if="item.logo != null" @click="OpenPhoto(item.logo)"
              ><v-avatar size="25px" class="mr-2"><img :src="item.logo" /></v-avatar
            ></a>
            {{
              (item.name != null ? item.name + " " : "") +
              (item.phone != null ? item.phone + " " : "") +
              (item.idcardNumber != null ? item.idcardNumber + " " : "") +
              (item.address != null ? item.address + " " : "")
            }}
            <span v-if="item.postcodeMapping != null">
              {{
                item.postcodeMapping.SubDistrict +
                " " +
                item.postcodeMapping.District +
                " " +
                item.postcodeMapping.Province +
                " " +
                item.postcodeMapping.Postcode
              }}
            </span>
            <v-chip v-if="item.isDefault" class="chip-set-13" color="teal" dark>
              ค่าเริ่มต้น
            </v-chip>
          </template>
        </v-data-table>

        <!-- Mobile hidden-sm-and-up -->
        <!-- <v-data-iterator
          :items="dataInvoice"
          :items-per-page="9999999999"
          hide-default-header
          hide-default-footer
          class="hidden-sm-and-up"
        >
          <template v-slot:default="props">
            <v-row class="ma-0">
              <v-col v-for="item in props.items" :key="item.id" cols="12" class="pa-1">
                <v-card>
                  <v-card-title class="subheading font-weight-bold pa-0">

                    <div class="px-0 v-list-item item-stert">
                      <a v-if="item.photoLink!=null && item.photoLink!=''" @click="OpenPhoto(item.photoLink)" class="v-avatar v-list-item__avatar my-0 mx-4 rounded-0 v-avatar--tile">
                        <img :src="item.photoLink" class="picsize">
                      </a>
                      <a v-else class="v-avatar v-list-item__avatar my-0 mx-3 rounded-0 v-avatar--tile" style="height: 90px; min-width: 90px; width: 90px;">
                        <span class="bold-color-gray-12"> No Picture </span>
                      </a>
                      <div class="v-list-item__content py-2" >

                        <div class="v-list-item__title subtitle-1 packhai-showproduct-2">
                          <div>
                            <div>
                              ชื่อ
                            </div>
                            <div style="line-height: 1rem !important;">
                              <v-tooltip top>
                                <template v-slot:activator="{ on, attrs }">
                                  <div v-bind="attrs" v-on="on" class="cut-text">
                                    <a v-if="item.logo!=null" @click="OpenPhoto(item.logo)"><v-avatar size="25px" class="mr-2" ><img :src="item.logo"/></v-avatar></a>
                                    <span v-if=" item.name != null">: {{ item.name }}</span>
                                  </div>
                                </template>
                                <span v-if=" item.name != null">{{ item.name }}</span>
                              </v-tooltip>
                              <v-chip v-if="item.isMasterUser" class="chip-set-13" color="teal" dark> Master User </v-chip>
                            </div>
                          </div>
                        </div>
                        <div>
                          <div class="v-markdown subtitle-1 packhai-showproduct-2">
                            <div v-if="item.isDefault">
                              <div>
                                ค่าเริ่มต้น
                              </div>
                              <div>
                                :<v-chip v-if="item.isDefault" class="chip-set-13" color="teal" dark> ค่าเริ่มต้น </v-chip>
                              </div>
                            </div>

                            <br/>
                            <div>
                              <div>
                                เบอร์โทร
                              </div>
                              <div>
                                : {{ item.phone }}
                              </div>
                            </div>

                          </div>
                        </div>

                      </div>
                      <div>
                        <v-menu bottom left>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn icon v-bind="attrs" v-on="on">
                              <v-icon size="18">mdi-dots-vertical</v-icon>
                            </v-btn>
                          </template>
                          <v-list class="v_list_dropdown">
                            <v-list-item v-for="(i, index) in CheckDropdown(item.isDefault)" :key="index">
                              <v-list-item-title @click="Letgo(i.title,true,item)">{{ i.title }}</v-list-item-title>
                            </v-list-item>
                          </v-list>
                        </v-menu>
                      </div>
                    </div>

                  </v-card-title>
                </v-card>
              </v-col>
            </v-row>
          </template>
        </v-data-iterator> -->
      </v-card-text>
    </v-card>

    <!-- บัญชีธนาคาร -->
    <v-dialog
      v-model="dialogBank"
      scrollable
      max-width="50%"
      persistent
      :fullscreen="$vuetify.breakpoint.xsOnly"
    >
      <v-card elevation="1" class="ma-0">
        <v-card-title>
          <v-icon size="22" color="orange darken-3">fa-money-check-alt</v-icon>
          <span v-if="!branchBank.isUpdate" class="ml-3">เพิ่มบัญชีธนาคาร</span>
          <span v-else class="ml-3">แก้ไขบัญชีธนาคาร</span>
          <v-spacer />
          <v-btn icon @click="dialogBank = false"
            ><v-icon color="danger">mdi-close</v-icon></v-btn
          >
        </v-card-title>
        <v-divider />
        <v-card-text>
          <v-form ref="formBranchBank" lazy-validation>
            <v-row>
              <v-col cols="12" md="3" class="pt-1 pb-2">
                <span class="fn-14"> หน้าบัญชี : </span>
              </v-col>
              <v-col cols="12" md="9" class="pt-1 pb-2">
                <span>
                  <v-btn
                    :elevation="0"
                    class="btn-inputfile ma-0"
                    color="orange darken-2"
                    @click="$refs.inputfile.click()"
                    ><span>อัพโหลดรูปภาพ</span></v-btn
                  >
                  {{ files.filename }}
                </span>
                <input
                  class="display-none d-none"
                  ref="inputfile"
                  accept="image/*"
                  type="file"
                  @change="previewpicture($event)"
                />
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" md="3" class="pt-1 pb-2">
                <span class="fn-14"> ชื่อธนาคาร : </span>
              </v-col>
              <v-col cols="12" md="9" class="pt-1 pb-2">
                <v-select
                  v-model="branchBank.bank"
                  :items="branchBank.bankList"
                  item-text="nameThai"
                  item-value="id"
                  ref="branchBankbank"
                  :rules="branchBank.rulesBankName"
                  persistent-hint
                  return-object
                  dense
                  hide-details
                  outlined
                  single-line
                >
                  <template slot="selection" slot-scope="data">
                    <v-avatar v-if="data.item.logo != null" size="25px" class="mr-2"
                      ><img :src="data.item.logo"
                    /></v-avatar>
                    {{ data.item.nameThai }}
                  </template>
                  <template slot="item" slot-scope="data">
                    <v-avatar v-if="data.item.logo != null" size="25px" class="mr-2"
                      ><img :src="data.item.logo"
                    /></v-avatar>
                    {{ data.item.nameThai }}
                  </template>
                </v-select>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" md="3" class="pt-1 pb-2">
                <span class="fn-14"> ชื่อบัญชี : </span>
              </v-col>
              <v-col cols="12" md="9" class="pt-1 pb-2">
                <v-text-field
                  :value="branchBank.accountName"
                  @change="(value) => (branchBank.accountName = Trim_value(value))"
                  ref="branchBankaccountName"
                  :rules="branchBank.rulesAccountName"
                  autocomplete="off"
                  outlined
                  dense
                  hide-details
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" md="3" class="pt-1 pb-2">
                <span class="fn-14"> เลขบัญชี : </span>
              </v-col>
              <v-col cols="12" md="9" class="pt-1 pb-2">
                <v-text-field
                  :value="branchBank.accountNumber"
                  @change="(value) => (branchBank.accountNumber = Trim_valueInt(value))"
                  ref="branchBankaccountNumber"
                  :rules="branchBank.rulesAccountNumber"
                  autocomplete="off"
                  outlined
                  dense
                  hide-details
                  @keypress="isNumberWNoDot($event)"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" md="3" class="pt-1 pb-2">
                <span class="fn-14"> สถานะ : </span>
              </v-col>
              <v-col cols="12" md="1" class="pt-1 pb-2">
                <v-switch
                  v-model="branchBank.isActive"
                  :readonly="branchBank.isReadonly"
                  color="success"
                  class="mx-2"
                ></v-switch>
              </v-col>
              <v-col cols="12" md="8" class="pt-1 pb-2">
                <span v-if="branchBank.isActive">เปิดใช้งาน</span>
                <span v-else>ไม่เปิดใช้งาน</span>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" md="3" class="pt-1 pb-2">
                <span class="fn-14"> ตั้งเป็นค่าเริ่มต้น : </span>
              </v-col>
              <v-col cols="12" md="1" class="pt-1 pb-2">
                <v-checkbox
                  v-model="branchBank.isDefault"
                  :readonly="branchBank.isReadonly"
                  color="info"
                ></v-checkbox>
              </v-col>
              <v-col cols="12" md="8" class="pt-1 pb-2">
                <span
                  v-if="branchBank.isReadonly && branchBank.isUpdate"
                  class="text-danger"
                  >ไม่สามารถแก้ไขได้ !!</span
                >
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" class="pt-1 pb-2">
                <v-btn
                  :elevation="1"
                  color="primary"
                  class="px-2"
                  @click="UpdateBranchBank()"
                >
                  <v-icon size="20" class="mr-1">mdi-content-save</v-icon>
                  <span class="fn-14"><b> บันทึก </b></span>
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-card elevation="1" class="v-card-bottom-30">
      <v-card-title class="height-57">
        <v-icon size="22" color="orange darken-3">fa-money-check-alt</v-icon
        ><span class="ml-3">บัญชีธนาคาร</span>
        <v-spacer />
        <v-btn
          :elevation="1"
          color="primary"
          class="px-2"
          @click="OPPBranchBank(false, null)"
        >
          <v-icon size="22" class="mr-1">mdi-plus</v-icon>
          เพิ่มบัญชี
        </v-btn>
      </v-card-title>
      <v-divider />
      <v-card-text class="overflow-y-auto packhai-max500-min100">
        <v-data-table
          :headers="headerBranchBank"
          :items="dataBranchBank"
          :items-per-page="9999999999"
          hide-default-footer
          class="packhai-table packhai-border-table"
        >
          <template v-slot:item.fileURL="{ item }">
            <a v-if="item.fileURL != null" @click="OpenPhoto(item.fileURL)"
              ><img :src="item.fileURL" width="60" class="mt-1"
            /></a>
          </template>
          <template v-slot:item.delete="{ item }">
            <a v-if="!item.isDefault" @click="DeleteBranchBank(item)"
              ><v-icon size="16" color="red darken-3">fa-trash-alt</v-icon></a
            >
            <v-icon v-else size="16"></v-icon>
          </template>
          <template v-slot:item.edit="{ item }">
            <a @click="OPPBranchBank(true, item)"
              ><v-icon size="16" color="orange darken-3">fa-edit</v-icon></a
            >
          </template>
          <template v-slot:item.isActive="{ item }">
            <v-row>
              <v-col cols="12" class="text-left">
                <span v-if="item.isActive"
                  ><v-icon size="10" color="success">mdi-checkbox-blank-circle</v-icon>
                  เปิดใช้งาน</span
                >
                <span v-else
                  ><v-icon size="10" color="danger">mdi-checkbox-blank-circle</v-icon>
                  ไม่เปิดใช้งาน</span
                >
              </v-col>
            </v-row>
          </template>
          <template v-slot:item.bankName="{ item }">
            <a v-if="item.logo != null" @click="OpenPhoto(item.logo)"
              ><v-avatar size="25px" class="mr-2"><img :src="item.logo" /></v-avatar
            ></a>
            {{ item.bankName + " " + item.accountNumber + " " + item.accountName }}
            <v-chip v-if="item.isDefault" class="chip-set-13" color="teal" dark>
              ค่าเริ่มต้น
            </v-chip>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
    <!-- ข้อมูลคลัง -->
    <v-card elevation="1" class="v-card-bottom-30">
      <v-card-title class="height-57">
        <v-icon size="22" color="success darken-3">fa-warehouse</v-icon
        ><span class="ml-3">คลังสินค้า</span>
        <v-spacer />
        <v-btn :elevation="1" color="primary" class="px-2" @click="EditWarehouse(null)">
          <v-icon size="22" class="mr-1">mdi-plus</v-icon>
          เพิ่มคลัง
        </v-btn>
      </v-card-title>
      <v-divider />
      <v-card-text>
        <!-- PC hidden-xs-only -->
        <v-data-table
          :headers="warehouse_header"
          :items="warehouse_data"
          :items-per-page="9999999999"
          hide-default-footer
          class="packhai-table packhai-border-table"
        >
          <template v-slot:item.no="{ item }">
            {{ formatMoney(warehouse_data.indexOf(item) + 1) }}
          </template>
          <template v-slot:item.address="{ item }">
            <span v-if="item.postcodemappingID != null"> {{ item.fullAddress }} </span>
            <span v-else> {{ item.address }} </span>
          </template>

          <template v-slot:item.edit="{ item }">
            <a @click="EditWarehouse(item)"
              ><v-icon size="18" color="orange darken-3">fa-edit</v-icon></a
            >
          </template>
          <template v-slot:item.delete="{ item }">
            <a v-if="warehouse_data.indexOf(item) != 0" @click="DeleteWarehouse(item)"
              ><v-icon size="18" color="red">fa-trash-alt</v-icon></a
            >
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>

    <!-- เชื่อมต่อ GOOGLE Drive -->
    <v-card elevation="1" class="v-card-bottom-30">
      <v-card-title class="height-57">
        <img
          src="../../../assets/icon_jpg/Google-drive-logo-on-transparent-background-PNG.png"
          width="30"
        />
        <span class="ml-3">เชื่อมต่อ Google Drive</span>
        <v-spacer />
        <v-btn :elevation="1" color="primary" class="px-2" @click="LoginGoogle()">
          <v-icon size="22" class="mr-1">mdi-plus</v-icon>
          เชื่อมต่อ Google Drive
        </v-btn>
      </v-card-title>
      <v-divider />
      <v-card-text class="overflow-y-auto packhai-max500-min100">
        <v-data-table
          :headers="GoogleDrive.header"
          :items="GoogleDrive.dataset"
          hide-default-footer
          class="packhai-table packhai-border-table"
        >
          <template v-slot:item.delete="{ item }">
            <v-icon size="16" color="red" @click="DeleteGoogleDrive(item)"
              >fa-trash-alt</v-icon
            >
          </template>
          <template v-slot:item.ID="{ item }">
            <span>
              <v-icon size="10" color="success">mdi-checkbox-blank-circle</v-icon>
              เปิดใช้งาน
            </span>
          </template>
          <template v-slot:item.created="{ item }">
            {{ item.created == null ? "" : formatDatetime(item.created) }}
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
    <!-- popup -->
    <v-dialog
      v-model="warehouse_dialog"
      scrollable
      max-width="45%"
      persistent
      :fullscreen="$vuetify.breakpoint.xsOnly"
    >
      <v-card elevation="1">
        <v-card-title class="height-57">
          <span v-if="warehouse_edit.id == 0">เพิ่มคลังสินค้า</span>
          <span v-else>แก้ไขคลังสินค้า</span>
          <v-spacer />
          <v-btn icon @click="warehouse_dialog = false"
            ><v-icon color="danger">mdi-close</v-icon></v-btn
          >
        </v-card-title>
        <v-divider />
        <v-card-text class="overflow-x-auto">
          <v-form ref="addWarehouseForm" lazy-validation>
            <v-row>
              <v-col cols="12" md="3"> ชื่อคลัง : </v-col>
              <v-col cols="12" md="9">
                <v-text-field
                  :value="warehouse_edit.name"
                  @change="(value) => (warehouse_edit.name = Trim_value(value))"
                  autocomplete="off"
                  :rules="rulesname"
                  ref="warehousename"
                  outlined
                  dense
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" md="3"> รหัสคลัง : </v-col>
              <v-col cols="12" md="9">
                <v-text-field
                  :value="warehouse_edit.warehouseCode"
                  @change="(value) => (warehouse_edit.warehouseCode = Trim_value(value))"
                  autocomplete="off"
                  ref="warehousewarehouseCode"
                  outlined
                  dense
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" md="3"> ที่อยู่ : </v-col>
              <v-col cols="12" md="9">
                <v-text-field
                  :value="warehouse_edit.address"
                  @change="(value) => (warehouse_edit.address = Trim_value(value))"
                  autocomplete="off"
                  ref="warehouseaddress"
                  outlined
                  dense
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" md="3"> รหัสไปรษณีย์ : </v-col>
              <v-col cols="12" md="9">
                <v-autocomplete
                  v-model="warehouse_edit.postcodeMapping"
                  ref="warehousepostcodeMapping"
                  placeholder="รหัสไปรษณีย์ / จังหวัด / (เขต/อำเภอ) / (แขวง/ตำบล)"
                  :items="postcode_mapping"
                  :item-text="
                    (item) =>
                      item.Postcode +
                      ' ' +
                      item.Province +
                      ' ' +
                      item.District +
                      ' ' +
                      item.SubDistrict
                  "
                  outlined
                  required
                  return-object
                  dense
                >
                </v-autocomplete>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" md="9">
                <v-btn
                  :elevation="1"
                  color="primary"
                  outlined
                  @click="addShopinWarehouse(warehouse_edit)"
                >
                  <v-icon size="20">mdi-plus</v-icon>
                  <span><b>เพิ่มร้านค้า </b></span>
                </v-btn>
              </v-col>
            </v-row>
            <v-row class="pt-5 pb-5">
              <v-col cols="12">
                <v-data-table
                  :headers="addShop.headerShopSelect"
                  :items="addShop.shopTable"
                  :items-per-page="9999999999"
                  :mobile-breakpoint="0"
                  hide-default-footer
                  class="packhai-border-table"
                >
                  <template v-slot:item.Delete="{ item }">
                    <a @click="handleDeleteShopInWarehouseClick(item)"
                      ><v-icon size="18" color="red">fa-trash-alt</v-icon></a
                    >
                  </template>
                  <template v-slot:item.No="{ item }">
                    {{
                      formatMoney(
                        addShop.shopTable.length - addShop.shopTable.indexOf(item)
                      )
                    }}
                  </template>
                  <template v-slot:item.openOrder="{ item }">
                    <v-row justify="center">
                      <v-radio-group
                        v-model="item.IsAllowAddOrderWhenStockShortage"
                        row
                        required
                        class="mb-2 is-cod-selector"
                        dense
                        hide-details
                      >
                        <v-radio :value="true">
                          <template v-slot:label> <span> เปิด </span> </template>
                        </v-radio>
                        <v-radio :value="false">
                          <template v-slot:label> <span>ปิด</span> </template>
                        </v-radio>
                      </v-radio-group>
                    </v-row>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" align="right">
                <v-btn :elevation="1" color="primary" @click="UpdateWarehouse()">
                  <v-icon size="20">mdi-content-save</v-icon>
                  <span class="fn-14"><b>บันทึก </b></span>
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- addShop -->
    <v-dialog
      v-model="editshop_dialog"
      scrollable
      max-width="35%"
      persistent
      :fullscreen="$vuetify.breakpoint.xsOnly"
    >
      <v-card elevation="1">
        <v-card-title class="height-57">
          <span>เพิ่มร้านในคลัง</span>
          <v-spacer />
          <v-btn icon @click="editshop_dialog = false"
            ><v-icon color="danger">mdi-close</v-icon></v-btn
          >
        </v-card-title>
        <v-divider />
        <v-card-text class="overflow-x-auto">
          <v-row class="pb-5 py2">
            <v-col cols="12" lg="9">
              <v-text-field
                v-model="addShop.dataSearch"
                @keyup.enter="SearchShop()"
                placeholder="ใส่ชื่อร้านค้าที่ต้องการ"
                autocomplete="off"
                outlined
                dense
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="12" lg="2" align="left">
              <v-btn :elevation="1" color="primary" @click="SearchShop()">
                <span class="fn-14"><b>ค้นหา </b></span>
              </v-btn>
            </v-col>
          </v-row>
          <v-row>
            <v-data-table
              v-model="addShop.showSelect"
              :headers="addShop.header"
              :items="addShop.data2"
              :items-per-page="9999999999"
              :mobile-breakpoint="0"
              hide-default-footer
              show-select
              class="packhai-border-table"
            >
              <template v-slot:item.No="{ item }">
                {{ formatMoney(addShop.data2.indexOf(item) + 1) }}
              </template>
            </v-data-table>
          </v-row>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-row>
            <v-col cols="12" class="pt-1 pb-2" align="right">
              <v-btn
                :elevation="1"
                color="primary"
                class="px-2"
                @click="handleAddShopInWarehouseClick()"
              >
                <v-icon size="20" class="mr-1">mdi-plus</v-icon>
                <span class="fn-14"><b> เพิ่ม </b></span>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
import Loading from "@/website/components/Loading";
import {
  generalService_dotnet,
  branchService_dotnet,
  shopService_dotnet,
} from "@/website/global";
import { AlertSuccess, AlertError } from "@/website/global_alert";
import {
  Trim_value,
  Trim_rules,
  DateNowNotNull,
  formatMoney,
  isNumberWNoDot,
  isNumberWithDot,
  isNumberWitMark,
  SetFileBase64,
  Trim_valueInt,
  formatDatetime,
} from "@/website/global_function";
export default {
  components: {
    Loading,
  },
  data: () => ({
    // page
    header_token: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("authorization_token"),
    },
    page_loading: true,
    loading: false,
    postcode_mapping: null,
    BranchID: localStorage.getItem("Branch_BranchID"),
    // แสดงภาพ
    ShowPhoto: false,
    ShowPhotoLink: "",
    warehouse_header: [
      { text: "No", align: "center", sortable: false, value: "no", width: "60px" },
      {
        text: "รหัสคลัง",
        align: "center",
        value: "warehouseCode",
        sortable: false,
        width: "150px",
      },
      { text: "ชื่อ", align: "left", value: "name", sortable: false },
      { text: "ที่อยู่", align: "left", value: "fullAddress", sortable: false },
      { text: "แก้ไข", align: "center", value: "edit", sortable: false, width: "90px" },
      { text: "ลบ", align: "center", value: "delete", sortable: false, width: "90px" },
    ],
    warehouse_dialog: false,
    editshop_dialog: false,
    addShop: {
      warehouseName: null,
      headerShopSelect: [
        { align: "center", text: "ลบ", value: "Delete", sortable: false, width: "50px" },
        { align: "left", text: "NO", value: "No", sortable: false, width: "50px" },
        { align: "left", text: "ชื่อร้านค้า", value: "name", sortable: false },
        {
          align: "center",
          text: "เปิดออเดอร์ได้หากสต๊อกไม่เพียงพอ",
          value: "openOrder",
          sortable: false,
          width: "250px",
        },
      ],
      header: [
        // { align: 'center', text: 'เลือก', value: 'select', sortable: false, width: '50px' },
        { align: "left", text: "NO", value: "No", sortable: false },
        {
          align: "left",
          text: "ชื่อร้านค้า",
          value: "name",
          sortable: false,
          width: "430px",
        },
      ],
      data: [],
      data2: [],
      selectShop: [],
      dataSearch: null,
      shopTable: [],
      DeleteDataShop: [],
      showSelect: [],
    },
    warehouse_data: [],
    warehouse_edit: {
      id: 0,
      name: null,
      branchId: localStorage.getItem("Branch_BranchID"),
      shopId: null,
      warehouseCode: null,
      address: null,
      postcodeMapping: null,
      postcodemappingId: null,
      created: null,
      createdBy: localStorage.getItem("staff_id"),
      isPackhaiWarehouse: true,
      isDeleted: false,
      isAllowAddOrderWhenStockShortage: false,
    },
    // Branch
    branch: {
      isUpdate: false,
      id: 0,
      name: null,
      senderName: null,
      phoneNumber: null,
      email: null,
      address: null,
      fullAddress: null,
      postcodeMappingId: 0,
      postcodeMapping: {},
    },
    rulesname: [],
    rulesaddress: [],
    rulespostcodeMapping: [],
    // Invoice
    invoice: {
      dialog: false,
      isReadonly: false,
      postcodeMapping: {},
      isUpdate: false,
      rulesName: [],
      rulesPhone: [],
      rulesIdCardNumber: [],
      rulesAddress: [],
      rulesPostcodeMapping: [],
      id: 0,
      branchId: localStorage.getItem("Branch_BranchID"),
      name: null,
      idcardNumber: null,
      phone: null,
      address: null,
      postcodeMappingId: 0,
      logo: null,
      isDefault: false,
    },
    dataInvoice: [],
    headerInvoice: [
      { align: "left", text: "ที่อยู่ใบกำกับภาษี", value: "address", sortable: false },
      { align: "center", text: "แก้ไข", value: "edit", sortable: false, width: "80px" },
      { align: "center", text: "ลบ", value: "delete", sortable: false, width: "80px" },
    ],

    // Bank
    dialogBank: false,
    branchBank: {
      isReadonly: false,
      isUpdate: false,
      bankList: [],
      bank: null,
      rulesBankName: [],
      rulesAccountName: [],
      rulesAccountNumber: [],
      id: 0,
      bankID: 0,
      branchID: localStorage.getItem("Branch_BranchID"),
      bankName: null,
      accountNumber: null,
      accountName: null,
      isActive: true,
      isDefault: false,
      fileURL: null,
    },
    dataBranchBank: [],
    headerBranchBank: [
      {
        align: "center",
        text: "หน้าบัญชี",
        value: "fileURL",
        sortable: false,
        width: "100px",
      },
      { align: "left", text: "บัญชีธนาคาร", value: "bankName", sortable: false },
      {
        align: "center",
        text: "สถานะ",
        value: "isActive",
        sortable: false,
        width: "150px",
      },
      { align: "center", text: "แก้ไข", value: "edit", sortable: false, width: "80px" },
      { align: "center", text: "ลบ", value: "delete", sortable: false, width: "80px" },
    ],

    // ภาพ
    files: {
      bucket: null,
      part: null,
      filename: null,
      filebase64: null,
      url: null,
    },

    GoogleDrive: {
      pickerApiLoaded: false,
      developerKey: "AIzaSyCCUDryNUdiqImwUSdga-7lzjnDA79yQe0",
      clientId: "73555754893-5sjbfn01j3lhjijla4jgtmd361dq4k6h.apps.googleusercontent.com",
      scope: "https://www.googleapis.com/auth/drive",
      oauthToken: null,
      dataset: [],
      header: [
        { text: "วันที่สร้าง", align: "center", value: "created", sortable: false },
        { text: "folderName", align: "left", value: "folderName", sortable: false },
        { text: "folderID", align: "left", value: "folderID", sortable: false },
        { text: "สถานะ", align: "left", value: "ID", sortable: false },
        { text: "ลบ", align: "center", value: "delete", sortable: false, width: "90px" },
      ],
    },
  }),
  computed: {},
  watch: {},
  async created() {
    document.title = this.$router.history.current.meta.title;
    console.log("17.03 Check refreshToken");
    await this.GetPostcodemapping();
    this.GetBranch();
    this.GetInvoice();
    this.GetWarehouse();
    this.page_loading = false;
    await this.GetBank();
    this.GetBranchBank();
    await this.Getgoogle();
  },
  methods: {
    // Branch
    async GetBranch() {
      let response = await axios.post(
        branchService_dotnet + "Branch/get-branch-single",
        {
          branchId: localStorage.getItem("Branch_BranchID"),
        },
        { headers: this.header_token }
      );
      if (response.status == 200) {
        var branch = response.data.branch;
        this.branch.id = branch.id;
        this.branch.name = branch.name;
        this.branch.senderName = branch.senderName;
        this.branch.phoneNumber = branch.phoneNumber;
        this.branch.email = branch.email;
        this.branch.address = branch.address;
        this.branch.postcodeMappingId = branch.postcodeMappingId;
        var postcodeMapping = this.SearchPostcodeMapping(this.branch.postcodeMappingId);
        this.branch.postcodeMapping = postcodeMapping;
        var address = "";
        if (postcodeMapping != null) {
          address =
            postcodeMapping.SubDistrict +
            " " +
            postcodeMapping.District +
            " " +
            postcodeMapping.Province +
            " " +
            postcodeMapping.Postcode;
        }
        this.branch.fullAddress = this.branch.address + " " + address;
      }
    },
    async GetWarehouse() {
      let warehouseResponse = await axios.get(
        branchService_dotnet + "Branch/get-warehouse-list?BranchID=" + this.BranchID,
        { headers: this.header_token }
      );
      if (warehouseResponse.status == 200 && warehouseResponse.data.status == "success") {
        this.warehouse_data = warehouseResponse.data.warehouseList;
        for (var i in this.warehouse_data) {
          if (this.warehouse_data[i].postcodemappingID != null) {
            this.warehouse_data[i].fullAddress =
              (this.warehouse_data[i].address == null
                ? ""
                : this.warehouse_data[i].address + " ") +
              this.warehouse_data[i].subDistrict +
              " " +
              this.warehouse_data[i].district +
              " " +
              this.warehouse_data[i].province +
              " " +
              this.warehouse_data[i].postcode;
          }
        }
      } else {
        this.$swal({
          type: "warning",
          text: "ไม่สามารถทำรายการได้ กรุณาทำรายการอีกครั้ง",
        });
      }
    },
    async EditWarehouse(item) {
      this.addShop.shopTable = [];
      this.addShop.DeleteDataShop = [];
      await this.getShopList();
      await this.getShopinWarehouseAccessList(item?.id ?? 0);
      if (item != null) {
        var postcodemapping = null;
        if (item.postcodemappingID != null) {
          for (var j in this.postcode_mapping) {
            if (item.postcodemappingID == this.postcode_mapping[j].Id) {
              postcodemapping = this.postcode_mapping[j];
              break;
            }
          }
        }
        item.postcodeMapping = postcodemapping;
        item.postcodemappingId = item.postcodemappingID;
        this.warehouse_edit = item;
      } else {
        this.addShop.shopTable = [];
        this.warehouse_edit = {
          id: 0,
          name: null,
          branchId: this.BranchID,
          shopId: null,
          warehouseCode: null,
          address: null,
          postcodeMapping: null,
          postcodemappingId: null,
          created: null,
          createdBy: localStorage.getItem("staff_id"),
          isPackhaiWarehouse: false,
          isDeleted: false,
          isAllowAddOrderWhenStockShortage: false,
        };
      }
      (this.rulesname = []),
        (this.rulesaddress = []),
        (this.rulespostcodeMapping = []),
        (this.warehouse_dialog = true);
    },
    // addShop
    async addShopinWarehouse(item) {
      this.addShop.warehouseName = item.name;
      this.addShop.dataSearch = null;
      this.addShop.showSelect = [];
      this.addShop.data2 = this.addShop.data.filter(
        (x) => !this.addShop.shopTable.map((i) => i.id).includes(x.id)
      );
      this.editshop_dialog = true;
    },
    async getShopList(dataSearch) {
      this.addShop.data = [];
      let response = await axios.post(
        shopService_dotnet + "Shop/get-shop-list",
        { branchId: localStorage.getItem("Branch_BranchID"), dataSearch: dataSearch },
        { headers: this.header_token }
      );
      if (response.status == 200) {
        if (response.data.result.length > 0) {
          for (var item in response.data.result) {
            this.addShop.data.push(response.data.result[item].shop);
          }
        }
      }
    },
    async getShopinWarehouseAccessList(warehouseID) {
      this.loading = true;
      let response = await axios.get(
        branchService_dotnet + "Branch/get-warehouseAccess?WarehouseID=" + warehouseID,
        { headers: this.header_token }
      );
      if (response.data.status) {
        this.addShop.data = this.addShop.data.map((item) => ({
          ...item,
          selectShop: response.data.warehouseAccessList
            .map((v) => v.shopId)
            .includes(item.id),
          IsAllowAddOrderWhenStockShortage:
            response.data.warehouseAccessList.find((j) => j.shopId === item.id)
              ?.isAllowAddOrderWhenStockShortage ?? false,
          accessID:
            response.data.warehouseAccessList.find((j) => j.shopId === item.id)?.id ?? 0,
        }));
        this.addShop.selectShop = [];
        for (var item of this.addShop.data) {
          // this.handleSelectCheckboxShop(item)
          if (item.selectShop) {
            const isExisting = this.addShop.shopTable.some((x) => x.id === item.id);
            if (!isExisting) {
              this.addShop.shopTable.unshift(item);
            }
          }
        }

        this.loading = false;
      }
    },
    async SearchShop() {
      var dataSearch = {
        name: this.addShop.dataSearch,
        condition: 0,
      };
      await this.getShopList(dataSearch);
    },
    async handleAddShopinWarehouseAccess(warehouseID) {
      let dataShop = this.addShop.shopTable.map((x) => ({
        ShopID: x.id,
        isAllowAddOrderWhenStockShortage: x.IsAllowAddOrderWhenStockShortage,
        WarehouseID: warehouseID,
        id: x.accessID,
      }));
      let response = await axios.post(
        branchService_dotnet + "Branch/update-warehouseAccess",
        {
          shopInWarehouseAccess: dataShop,
          shopDelete: this.addShop.DeleteDataShop,
        },
        { headers: this.header_token }
      );
      if (!response.data.status) {
        this.$swal({
          type: "warning",
          text: "ไม่สามารถทำรายการได้ กรุณาทำรายการอีกครั้ง",
        });
      }
    },
    async handleAddShopInWarehouseClick() {
      this.addShop.shopTable.push(...this.addShop.showSelect);

      this.editshop_dialog = false;
    },
    async handleDeleteShopInWarehouseClick(item) {
      this.addShop.DeleteDataShop.push(item.accessID);
      const index = this.addShop.shopTable.indexOf(item);
      if (index > -1) {
        this.addShop.shopTable.splice(index, 1);
      }
    },
    HandleUpdateLabels(isAllowAddOrder) {
      var label = "ปิด";
      if (isAllowAddOrder) {
        label = "เปิด";
      }
      return label;
    },
    //
    async DeleteWarehouse(item) {
      Swal.fire({
        icon: "question",
        text: "คลัง: " + item.name + " ต้องการลบใช่หรือไม่",
        confirmButtonText: "ยืนยัน",
        cancelButtonText: "ยกเลิก",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        allowOutsideClick: false,
      }).then(async (result) => {
        if (result.value) {
          this.loading = true;
          let response = await axios.get(
            branchService_dotnet + "Branch/delete-warehouse?WarehouseID=" + item.id,
            { headers: this.header_token }
          );
          if (response.status == 200) {
            this.loading = false;
            this.GetWarehouse();
            Swal.fire({
              toast: true,
              timer: 1500,
              showConfirmButton: false,
              position: "top",
              type: "success",
              title: "Success",
            });
          } else {
            this.loading = false;
            this.$swal({
              type: "error",
              text: "ไม่สามารถทำรายการได้",
            });
          }
        }
      });
    },
    async rulesAddWarehouseCheck() {
      this.rulesname = [(v) => !!v || "กรุณาระบุ"];
      // this.rulesaddress = [ v => !!v || 'กรุณาระบุ', ]
      // this.rulespostcodeMapping = [ v => !!v || 'กรุณาระบุ', ]
    },
    async UpdateWarehouse() {
      await this.rulesAddWarehouseCheck();
      if (this.$refs.addWarehouseForm.validate()) {
        this.loading = true;

        if (this.warehouse_edit.postcodeMapping != null) {
          this.warehouse_edit.postcodemappingId = this.warehouse_edit.postcodeMapping.Id;
        }
        if (this.warehouse_edit.warehouseCode == "") {
          this.warehouse_edit.warehouseCode = null;
        }
        let response = await axios.post(
          branchService_dotnet + "Branch/update-warehouse",
          this.warehouse_edit,
          { headers: this.header_token }
        );
        if (response.status == 200) {
          this.loading = false;
          this.warehouse_dialog = false;
          await this.handleAddShopinWarehouseAccess(response.data.id);
          Swal.fire({
            toast: true,
            timer: 1500,
            showConfirmButton: false,
            position: "top",
            icon: "success",
            title: "บันทีกเรียบร้อย",
          });
          this.GetWarehouse();
        } else {
          this.loading = false;
          this.$swal({
            icon: "error",
            text: "ไม่สามารถทำรายการได้",
          });
        }
      }
    },
    async UpdateBranch() {
      if (this.$refs.formBranch.validate()) {
        this.loading = true;
        this.branch.postcodeMappingId = this.branch.postcodeMapping.Id;
        let response = await axios.post(
          branchService_dotnet + "Branch/update-branch",
          {
            id: this.branch.id,
            name: this.branch.name,
            senderName: this.branch.senderName,
            phoneNumber: this.branch.phoneNumber,
            email: this.branch.email,
            address: this.branch.address,
            postcodeMappingId: this.branch.postcodeMappingId,
          },
          { headers: this.header_token }
        );
        if (response.status == 200) {
          this.loading = false;
          var address = "";
          var postcodeMapping = this.branch.postcodeMapping;
          if (postcodeMapping != null) {
            address =
              postcodeMapping.SubDistrict +
              " " +
              postcodeMapping.District +
              " " +
              postcodeMapping.Province +
              " " +
              postcodeMapping.Postcode;
          }
          this.branch.fullAddress = this.branch.address + " " + address;
          this.branch.isUpdate = false;
          this.AlertSuccess();
        } else {
          this.loading = false;
          this.AlertError();
        }
      }
    },

    // Invoice
    async GetInvoice() {
      let response = await axios.post(
        branchService_dotnet + "Branch/get-branch-addressinvoice-list",
        {
          branchId: localStorage.getItem("Branch_BranchID"),
        },
        { headers: this.header_token }
      );
      if (response.status == 200) {
        this.dataInvoice = response.data;
        for (var i in this.dataInvoice) {
          this.dataInvoice[i].postcodeMapping = null;
          if (this.dataInvoice[i].postcodeMappingId != null) {
            this.dataInvoice[i].postcodeMapping = this.SearchPostcodeMapping(
              this.dataInvoice[i].postcodeMappingId
            );
          }
        }
      }
    },
    async OPPInvoice(isUpdate, item) {
      await this.rulesInvoice();
      this.clearFile();
      if (!isUpdate) {
        this.invoice.id = 0;
        this.invoice.name = null;
        this.invoice.idcardNumber = null;
        this.invoice.phone = null;
        this.invoice.address = null;
        this.invoice.postcodeMappingId = 0;
        this.invoice.postcodeMapping = null;
        this.invoice.logo = null;
        this.invoice.isDefault = false;
        this.invoice.isReadonly = false;

        var isDefault = false;
        for (var a in this.dataInvoice) {
          if (this.dataInvoice[a].isDefault) {
            isDefault = true;
            break;
          }
        }
        if (isDefault) {
          this.invoice.isDefault = !isDefault;
        } else {
          this.invoice.isDefault = !isDefault;
        }
      } else {
        this.invoice.id = item.id;
        this.invoice.name = item.name;
        this.invoice.idcardNumber = item.idcardNumber;
        this.invoice.phone = item.phone;
        this.invoice.address = item.address;
        this.invoice.postcodeMappingId = item.postcodeMappingId;
        this.invoice.postcodeMapping = item.postcodeMapping;
        this.invoice.logo = item.logo;
        this.invoice.isDefault = item.isDefault;
        this.invoice.isReadonly = item.isDefault ? true : false;

        // ภาพ
        if (item.logo != null && item.logo != "") {
          var file_name = item.logo.split("/");
          this.files.filename = file_name[parseInt(file_name.length) - 1];
        }
      }
      this.invoice.isUpdate = isUpdate;
      this.invoice.dialog = true;
    },
    async UpdateInvoice() {
      await this.rulesInvoiceCheck();
      if (this.$refs.formInvoice.validate()) {
        this.loading = true;
        this.invoice.postcodeMappingId = this.invoice.postcodeMapping.Id;
        var filesTemp = {
          bucket: this.files.bucket,
          part: this.files.part,
          filename: null,
          filebase64: this.files.filebase64,
          url: null,
        };
        let response = await axios.post(
          branchService_dotnet + "Branch/update-branch-addressinvoice",
          {
            branchId: this.invoice.branchId,
            id: this.invoice.id,
            name: this.invoice.name,
            phone: this.invoice.phone,
            idcardNumber: this.invoice.idcardNumber,
            address: this.invoice.address,
            postcodeMappingId: this.invoice.postcodeMappingId,
            isDefault: this.invoice.isDefault,
            logo: this.invoice.logo,
            files: filesTemp,
          },
          { headers: this.header_token }
        );
        if (response.status == 200) {
          this.GetInvoice();
          this.loading = false;
          this.invoice.dialog = false;
          this.AlertSuccess();
        } else {
          this.loading = false;
          this.AlertError();
        }
      }
    },
    async DeleteInvoice(item) {
      Swal.fire({
        position: "top",
        icon: "warning",
        text: item.name + " ลบ ใช่ หรือ ไม่",
        confirmButtonText: "ยืนยัน",
        cancelButtonText: "ยกเลิก",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        allowOutsideClick: false,
      }).then(async (result) => {
        if (result.value) {
          this.loading = true;
          let response = await axios.post(
            branchService_dotnet + "Branch/delete-branch-addressinvoice",
            {
              branchAddressInvoiceId: item.id,
            },
            { headers: this.header_token }
          );
          this.loading = false;
          if (response.status == 200) {
            this.dataInvoice.splice(this.dataInvoice.indexOf(item), 1);
            this.AlertSuccess();
          } else {
            this.AlertError();
          }
        }
      });
    },
    async InvoiceCloseDialog() {
      this.invoice.dialog = false;
    },
    rulesInvoiceCheck() {
      this.invoice.rulesName = [(v) => !!v || "กรุณาระบุ"];
      this.invoice.rulesPhone = [(v) => !!v || "กรุณาระบุ"];
      this.invoice.rulesIdCardNumber = [(v) => !!v || "กรุณาระบุ"];
      this.invoice.rulesAddress = [(v) => !!v || "กรุณาระบุ"];
      this.invoice.rulesPostcodeMapping = [(v) => !!v || "กรุณาระบุ"];
    },
    rulesInvoice() {
      this.invoice.rulesName = [];
      this.invoice.rulesPhone = [];
      this.invoice.rulesIdCardNumber = [];
      this.invoice.rulesAddress = [];
      this.invoice.rulesPostcodeMapping = [];
    },

    // Bank
    async GetBank() {
      let response = await axios.post(
        branchService_dotnet + "Branch/get-all-bank-list",
        {},
        { headers: this.header_token }
      );
      if (response.status == 200) {
        this.branchBank.bankList = response.data;
        this.branchBank.bankList.splice(0, 0, { id: 0, nameThai: "--เลือกธนาคาร--" });
        this.branchBank.bank = { id: 0, nameThai: "--เลือกธนาคาร--" };
      }
    },
    async GetBranchBank() {
      let response = await axios.post(
        branchService_dotnet + "Branch/get-branchbankaccount-list",
        {
          branchId: localStorage.getItem("Branch_BranchID"),
        },
        { headers: this.header_token }
      );
      if (response.status == 200) {
        this.dataBranchBank = response.data;
        for (var i in this.dataBranchBank) {
          this.dataBranchBank[i].logo = null;
          for (var j in this.branchBank.bankList) {
            if (this.branchBank.bankList[j].id == this.dataBranchBank[i].bankID) {
              this.dataBranchBank[i].logo = this.branchBank.bankList[j].logo;
              break;
            }
          }
        }
      }
    },
    async OPPBranchBank(isUpdate, item) {
      await this.rulesBranchBank();
      this.clearFile();
      if (!isUpdate) {
        this.branchBank.id = 0;
        this.branchBank.bankID = 0;
        this.branchBank.bankName = null;
        this.branchBank.accountNumber = null;
        this.branchBank.accountName = null;
        this.branchBank.isActive = true;
        this.branchBank.isDefault = false;
        this.branchBank.isReadonly = false;
        this.branchBank.bank = this.branchBank.bankList[0];
        this.branchBank.fileURL = null;

        var isDefault = false;
        for (var a in this.dataBranchBank) {
          if (this.dataBranchBank[a].isDefault) {
            isDefault = true;
            break;
          }
        }
        if (isDefault) {
          this.branchBank.isDefault = !isDefault;
        } else {
          this.branchBank.isDefault = !isDefault;
        }
      } else {
        this.branchBank.id = item.id;
        this.branchBank.bankID = item.bankID;
        this.branchBank.bankName = item.bankName;
        this.branchBank.accountNumber = item.accountNumber;
        this.branchBank.accountName = item.accountName;
        this.branchBank.isActive = item.isActive;
        this.branchBank.isDefault = item.isDefault;
        this.branchBank.isReadonly = item.isDefault ? true : false;
        this.branchBank.fileURL = item.fileURL;
        for (var i in this.branchBank.bankList) {
          if (this.branchBank.bankList[i].id == this.branchBank.bankID) {
            this.branchBank.bank = this.branchBank.bankList[i];
            break;
          }
        }

        // ภาพ
        if (item.fileURL != null && item.fileURL != "") {
          var file_name = item.fileURL.split("/");
          this.files.filename = file_name[parseInt(file_name.length) - 1];
        }
      }
      this.branchBank.isUpdate = isUpdate;
      this.dialogBank = true;
    },
    async UpdateBranchBank() {
      await this.rulesBranchBankCheck();
      if (this.$refs.formBranchBank.validate()) {
        this.loading = true;
        this.branchBank.bankID = this.branchBank.bank.id;
        this.branchBank.bankName = this.branchBank.bank.nameThai;
        if (this.branchBank.isDefault) {
          this.branchBank.isActive = this.branchBank.isDefault;
        }
        var filesTemp = {
          bucket: this.files.bucket,
          part: this.files.part,
          filename: null,
          filebase64: this.files.filebase64,
          url: null,
        };
        let response = await axios.post(
          branchService_dotnet + "Branch/update-branchbankaccount",
          {
            branchBankAccount: this.branchBank,
            files: filesTemp,
          },
          { headers: this.header_token }
        );
        if (response.status == 200) {
          this.GetBranchBank();
          this.loading = false;
          this.dialogBank = false;
          this.AlertSuccess();
        } else {
          this.loading = false;
          this.AlertError();
        }
      }
    },
    async DeleteBranchBank(item) {
      Swal.fire({
        position: "top",
        icon: "warning",
        text: item.bankName + " " + item.accountNumber + " ลบ ใช่ หรือ ไม่",
        confirmButtonText: "ยืนยัน",
        cancelButtonText: "ยกเลิก",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        allowOutsideClick: false,
      }).then(async (result) => {
        if (result.value) {
          this.loading = true;
          let response = await axios.post(
            branchService_dotnet + "Branch/delete-branchbankaccount",
            {
              branchBankAccountId: item.id,
            },
            { headers: this.header_token }
          );
          this.loading = false;
          if (response.status == 200) {
            this.dataBranchBank.splice(this.dataBranchBank.indexOf(item), 1);
            this.AlertSuccess();
          } else {
            this.AlertError();
          }
        }
      });
    },
    rulesBranchBankCheck() {
      this.branchBank.rulesBankName = [(v) => v.id != 0 || "กรุณาระบุ"];
      this.branchBank.rulesAccountName = [(v) => !!v || "กรุณาระบุ"];
      this.branchBank.rulesAccountNumber = [(v) => !!v || "กรุณาระบุ"];
    },
    rulesBranchBank() {
      this.branchBank.rulesBankName = [];
      this.branchBank.rulesAccountName = [];
      this.branchBank.rulesAccountNumber = [];
    },

    // ภาพ
    clearFile() {
      this.files.bucket = null;
      this.files.part = null;
      this.files.filename = null;
      this.files.filebase64 = null;
      this.files.url = null;
    },
    async previewpicture(event) {
      var part = this.invoice.dialog
        ? "Branch/AddressInvoice"
        : this.dialogBank
        ? "Branch/BankAccount"
        : null;
      var file = event.target.files[0];
      if (file != undefined && part != null) {
        this.files.bucket = "packhaistorage";
        this.files.part = part;
        this.files.filename = file.name;
        this.files.filebase64 = await this.SetFileBase64(file);
      }
    },

    async GetPostcodemapping() {
      let response = await axios.post(
        generalService_dotnet + "General/get-postcodeMapping",
        {},
        { headers: this.header_token }
      );
      if (response.status == 200) {
        this.postcode_mapping = response.data;
      }
    },
    SearchPostcodeMapping(postcodeMappingId) {
      for (var i in this.postcode_mapping) {
        if (parseInt(this.postcode_mapping[i].Id) == parseInt(postcodeMappingId)) {
          return this.postcode_mapping[i];
        }
      }
      return null;
    },
    OpenPhoto(Photo) {
      this.ShowPhotoLink = Photo;
      this.ShowPhoto = true;
    },
    Opentap(PhotoLink) {
      window.open(PhotoLink);
    },
    CheckDropdown(isDefault) {
      var result = [{ title: "แก้ไข" }];
      if (!isDefault) result.push({ title: "ลบ" });
      return result;
    },
    Letgo(text, isUpdate, item) {
      if (text === "แก้ไข") this.OPPInvoice(isUpdate, item);
      else this.DeleteInvoice(item);
    },
    // เชื่อมต่อ Google Drive
    LoginGoogle() {
      var oauth2Endpoint = "https://accounts.google.com/o/oauth2/v2/auth";
      var form = document.createElement("form");
      form.setAttribute("method", "GET"); // Send as a GET request.
      form.setAttribute("action", oauth2Endpoint);
      // Parameters to pass to OAuth 2.0 endpoint.S
      var params = {
        client_id:
          "73555754893-5sjbfn01j3lhjijla4jgtmd361dq4k6h.apps.googleusercontent.com",
        redirect_uri: "https://warehouse.packhai.com/connectGoogleDrive",
        response_type: "token code",
        scope:
          "https://www.googleapis.com/auth/drive.file https://www.googleapis.com/auth/drive.appdata",
        include_granted_scopes: "true",
        access_type: "offline",
        state: "state_parameter_passthrough_value&",
        prompt: "consent",
        enable_granular_consent: "true",
        "include_granted_scopes:": "Enables ",
      };
      // Add form parameters as hidden input values.
      for (var p in params) {
        var input = document.createElement("input");
        input.setAttribute("type", "hidden");
        input.setAttribute("name", p);
        input.setAttribute("value", params[p]);
        form.appendChild(input);
      }

      // Add form to page and submit it to open the OAuth 2.0 endpoint.
      document.body.appendChild(form);
      form.submit();
      // this.createPicker();
    },
    async Getgoogle() {
      return await axios
        .post(generalService_dotnet + "GoogleDrive/get-branch-google-drive", {
          branchID: localStorage.getItem("Branch_BranchID"),
        })
        .then(async (res) => {
          console.log(res);
          this.GoogleDrive.dataset = await res.data.item;
          return res.data.item;
        })
        .catch((error) => {
          this.loading = false;
          this.AlertError();
          return false;
        });
    },
    async DeleteGoogleDrive(item) {
      Swal.fire({
        icon: "question",
        text: "ลบการเชื่อมต่อ",
        confirmButtonText: "ใช้",
        cancelButtonText: "ไม่ใช้",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        allowOutsideClick: false,
      }).then(async (result) => {
        if (result.value) {
          await axios
            .post(generalService_dotnet + "GoogleDrive/delete-branch-google-drive", {
              branchID: localStorage.getItem("Branch_BranchID"),
              id: item.id,
            })
            .then(async (res) => {
              console.log(res.data.status);
              if (res.data.status == "success") {
                this.AlertSuccess();
                location.reload();
              }
              return;
            })
            .catch((error) => {
              this.loading = false;
              this.AlertError();
              return false;
            });
          console.log(result.value);
        }
      });
      console.log(item);
    },
    Trim_value,
    Trim_rules,
    DateNowNotNull,
    formatMoney,
    isNumberWNoDot,
    isNumberWithDot,
    isNumberWitMark,
    Trim_valueInt,
    SetFileBase64,
    AlertSuccess,
    AlertError,
    formatDatetime,
  },
};
</script>
